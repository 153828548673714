define("discourse/plugins/swapd-close-topic/discourse/connectors/topic-footer-main-buttons-before-create/custom-close-topic", ["exports", "@glimmer/component", "@ember/object", "@glimmer/tracking", "discourse/lib/ajax", "discourse/lib/ajax-error", "@ember/service"], function (_exports, _component, _object, _tracking, _ajax, _ajaxError, _service) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  class MyComponent extends _component.default {
    static #_ = (() => dt7948.g(this.prototype, "siteSettings", [_service.inject]))();
    #siteSettings = (() => (dt7948.i(this, "siteSettings"), void 0))();
    static #_2 = (() => dt7948.g(this.prototype, "dialog", [_service.inject]))();
    #dialog = (() => (dt7948.i(this, "dialog"), void 0))();
    close() {
      this.dialog.alert({
        message: I18n.t("custom_close_topic.message"),
        buttons: [{
          icon: "lock",
          label: I18n.t("custom_close_topic.just_close"),
          class: "btn-default",
          action: () => this.customClose()
        }, {
          icon: "dollar-sign",
          label: I18n.t("custom_close_topic.close_mark_sold"),
          class: "btn-danger",
          action: () => this.customClose(true)
        }, {
          label: I18n.t("composer.cancel")
        }]
      });
    }
    static #_3 = (() => dt7948.n(this.prototype, "close", [_object.action]))();
    customClose(mark_sold) {
      this.loading = true;
      (0, _ajax.ajax)(`/t/${this.args.outletArgs.topic.id}/custom_close`, {
        type: "POST",
        data: {
          mark_sold
        }
      }).then(data => {
        location.reload();
      }).catch(_ajaxError.popupAjaxError).finally(() => this.loading = false);
    }
    static #_4 = (() => dt7948.n(this.prototype, "customClose", [_object.action]))();
  }
  _exports.default = MyComponent;
});